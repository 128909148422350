import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import { Place, QrCode } from '@mui/icons-material';
import Container from '@mui/material/Container';
import { useEffect, useLayoutEffect } from 'react';
import { Beacons } from './beacons/Beacons';
import Dashboard from './dashboard/Dashboard';
import { QrCodes } from './qrCodes/QrCodes';
import { Plan as PlanType } from '../../../common/types';
import { Integration } from './Integration';

export interface PlanProps {
    plan: PlanType
}

export default function Plan(props: PlanProps) {
    console.log("Rendering Plan");
    console.log(props.plan);

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="icon position tabs example"
                style={{ minHeight: 60, maxHeight: 62 }}
            >
                <Tab icon={<DashboardIcon />} iconPosition="start" label="Dashboard" />
                <Tab icon={<Place />} iconPosition="start" label="Beacons" />
                <Tab icon={<QrCode />} iconPosition="start" label="QR codes" />
                <Tab icon={<BarChartIcon />} iconPosition="start" label="Reports" />
                <Tab icon={<LayersIcon />} iconPosition="start" label="Integrations" />
            </Tabs>
            <Container maxWidth={false} style={{ paddingLeft: 18, overflow: "hidden", height: "100%" }} sx={{ mt: 1, mb: 1 }}>
                {(() => {
                    switch (value) {
                        case 0:
                            return <Dashboard />
                        case 1:
                            return <Beacons plan={props.plan} />
                        case 2:
                            return <QrCodes plan={props.plan} />
                        case 3:
                            return <div>Comming soon...</div>
                        case 4:
                            return <Integration plan={props.plan} />
                        default:
                            return null
                    }
                })()}
            </Container>
        </>
    );
}